import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) =>
  <svg width='343' height='195' viewBox='0 0 343 195' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0)'>
      <path d='M22.4494 187.995H319.058C324.368 187.99 329.512 186.149 333.616 182.785C337.72 179.422 340.531 174.743 341.571 169.544C346.527 144.975 340.974 124.119 286.155 86.6739C223.922 44.1667 216.589 -14.8891 142.569 3.42462C102.715 13.2868 98.2902 32.8415 37.8718 79.1352C8.01231 102.014 0.327484 124.867 0.00369383 160.27C-0.19067 181.495 7.83463 187.995 22.4494 187.995Z' fill='#F2F6FF'/>
      <path d='M128.107 124.983C128.107 124.983 128.946 120.96 127.598 118.436C126.249 115.913 125.406 117.467 125.488 119.529C125.57 121.592 125.984 126.064 125.984 126.064' fill='#E69E87'/>
      <path d='M127.54 122.859C127.54 122.859 126.575 114.153 119.213 120.269C117.796 121.445 121.716 127.367 121.716 127.367L127.54 122.859Z' fill='#E69E87'/>
      <path d='M76.5817 187.736C72.1569 187.736 69.88 186.109 69.719 185.989L70.257 185.269C70.3219 185.314 76.9285 189.967 89.8797 182.801C103.505 175.262 104.949 168.612 108.435 152.547C109.552 147.4 110.943 140.995 113.028 133.368C114.934 126.395 122.246 118.983 132.04 118.983V119.884C122.693 119.884 115.716 126.953 113.898 133.606C111.819 141.209 110.432 147.601 109.317 152.738C105.872 168.608 104.3 175.852 90.3166 183.589C84.5019 186.805 79.9274 187.736 76.5817 187.736Z' fill='black'/>
      <path d='M119.211 120.269C119.164 120.423 119.141 120.584 119.143 120.745C119.283 121.558 120.167 122.491 120.811 122.589C121.862 122.748 124.838 121.229 125.79 120.353C126.68 119.532 125.438 117.664 125.283 117.416C125.277 117.409 125.271 117.401 125.267 117.393C124.388 116.237 119.606 119.112 119.211 120.269Z' fill='#E69E87'/>
      <path d='M171.238 113.732C171.238 113.732 167.751 111.886 168.53 102.404L161.325 101.07C161.325 101.07 162.135 109.571 158.49 112.27L171.238 113.732Z' fill='#E69E87'/>
      <g opacity='0.15'>
        <path opacity='0.15' d='M159.966 110.492L165.954 105.921L161.253 105.872C161.116 107.479 160.68 109.046 159.966 110.492Z' fill='black'/>
      </g>
      <path d='M172.618 98.7277C172.119 101.605 170.547 104.328 168.006 105.257C165.236 106.355 162.239 106.759 159.276 106.434C151.389 105.789 152.949 87.8825 156.694 84.4036C160.438 80.9247 171.428 83.1279 172.765 88.9457C173.456 91.9554 173.093 95.99 172.618 98.7277Z' fill='#E69E87'/>
      <path d='M151.177 82.7424C151.177 80.3636 154.141 76.4663 162.05 81.8419L162.058 81.8473C164.112 81.7897 166.165 82.0138 168.159 82.5132C169.587 82.9252 170.922 83.6103 172.089 84.5305C174.29 86.3137 175.895 89.2473 175.147 93.9618C173.848 102.155 170.487 104.341 168.793 105.346C168.362 105.602 168.257 105.391 168.107 104.916C167.638 103.147 167.302 101.345 167.102 99.5256C166.845 97.6344 165.143 96.4321 165.348 94.1671C165.421 93.3751 166.697 90.4748 162.746 90.4415C160.523 90.4234 161.354 90.5117 158.982 90.5117C158.644 90.5117 158.345 90.4964 158.07 90.4721H158.067C149.878 90.4721 151.177 85.1209 151.177 82.7424Z' fill='#151A30'/>
      <path d='M164.736 97.4559C164.687 98.1811 164.926 98.8964 165.401 99.4472C165.876 99.9979 166.549 100.34 167.274 100.399C168 100.458 168.72 100.23 169.278 99.7633C169.836 99.2968 170.188 98.6297 170.258 97.9062C170.294 97.543 170.256 97.1762 170.147 96.8278C170.039 96.4793 169.861 96.1563 169.624 95.878C169.388 95.5997 169.097 95.3717 168.771 95.2078C168.444 95.0438 168.088 94.9471 167.723 94.9235C165.748 94.8609 164.86 95.9335 164.736 97.4559Z' fill='#E69E87'/>
      <path d='M165.954 88.6816L167.993 89.4273C167.993 89.4273 167.272 99.6038 166.699 102.384C166.127 105.165 164.532 106.977 162.722 107.132C159.942 107.37 157.928 107.389 155.89 106.662C153.59 105.841 153.912 99.6822 153.912 99.6822C153.912 99.6822 161.311 100.482 163.357 99.5021C165.404 98.5218 165.954 88.6816 165.954 88.6816Z' fill='#151A30'/>
      <path d='M161.335 96.9622C161.696 96.9622 161.989 96.67 161.989 96.3097C161.989 95.9493 161.696 95.6572 161.335 95.6572C160.975 95.6572 160.682 95.9493 160.682 96.3097C160.682 96.67 160.975 96.9622 161.335 96.9622Z' fill='#151A30'/>
      <path d='M156.181 96.5393C156.541 96.5393 156.834 96.2472 156.834 95.8868C156.834 95.5265 156.541 95.2344 156.181 95.2344C155.82 95.2344 155.527 95.5265 155.527 95.8868C155.527 96.2472 155.82 96.5393 156.181 96.5393Z' fill='#151A30'/>
      <path d='M155.049 94.0467C154.967 94.0473 154.889 94.0155 154.831 93.9584C154.773 93.9012 154.74 93.8235 154.74 93.7421C154.739 93.6608 154.771 93.5826 154.828 93.5247C154.885 93.4668 154.963 93.434 155.045 93.4335L156.775 93.4123C156.816 93.4109 156.856 93.4177 156.894 93.4323C156.932 93.4469 156.966 93.4689 156.995 93.4972C157.024 93.5254 157.047 93.5592 157.063 93.5965C157.079 93.6339 157.086 93.674 157.086 93.7144C157.087 93.7547 157.079 93.7947 157.064 93.8321C157.049 93.8695 157.027 93.9036 156.999 93.9325C156.971 93.9613 156.937 93.9843 156.9 94.0002C156.863 94.0161 156.823 94.0246 156.783 94.0251L155.052 94.0467H155.049Z' fill='#151A30'/>
      <path d='M162.746 94.6772C162.722 94.6771 162.698 94.6744 162.674 94.6691L160.967 94.2584C160.89 94.2379 160.823 94.188 160.782 94.1192C160.741 94.0504 160.729 93.9682 160.747 93.8903C160.766 93.8124 160.815 93.7449 160.882 93.7023C160.95 93.6596 161.032 93.6453 161.111 93.6622L162.817 94.0724C162.89 94.09 162.954 94.1337 162.997 94.1952C163.04 94.2568 163.059 94.3319 163.05 94.4063C163.041 94.4808 163.006 94.5495 162.95 94.5994C162.894 94.6493 162.821 94.677 162.746 94.6772Z' fill='#151A30'/>
      <g opacity='0.15'>
        <path opacity='0.15' d='M158.435 99.4786C158.412 99.4786 158.39 99.4753 158.369 99.4687L156.796 98.986C156.765 98.9767 156.737 98.9609 156.713 98.9399C156.689 98.9188 156.67 98.893 156.657 98.8639C156.644 98.8349 156.637 98.8034 156.637 98.7715C156.636 98.7396 156.643 98.708 156.656 98.6789L157.69 96.3793C157.715 96.3256 157.76 96.2839 157.816 96.2632C157.872 96.2425 157.933 96.2445 157.987 96.2688C158.041 96.2931 158.084 96.3377 158.105 96.3929C158.126 96.4482 158.125 96.5096 158.102 96.5639L157.173 98.6307L158.501 99.0382C158.553 99.0541 158.597 99.0879 158.626 99.1335C158.655 99.1792 158.666 99.2336 158.658 99.2869C158.65 99.3403 158.623 99.389 158.582 99.4242C158.541 99.4593 158.489 99.4787 158.435 99.4786Z' fill='black'/>
      </g>
      <path d='M156.801 102.237C156.801 102.237 159.213 101.983 160.905 102.573C160.905 102.573 160.892 100.987 158.983 100.83C157.074 100.674 156.801 102.237 156.801 102.237Z' fill='white'/>
      <path d='M143.686 183.912C142.83 183.768 133.164 182.12 133.164 182.12L134.758 172.799C134.758 172.799 144.399 174.444 145.254 174.586L143.686 183.912Z' fill='#E69E87'/>
      <path d='M127.65 192.853C127.609 193.104 127.62 193.361 127.683 193.607C127.746 193.853 127.859 194.084 128.016 194.285C128.172 194.486 128.368 194.652 128.592 194.774C128.815 194.896 129.061 194.97 129.315 194.993C129.667 195.023 130.02 194.953 130.333 194.791C130.646 194.628 130.906 194.379 131.083 194.074L136.545 183.828C136.65 183.629 136.71 183.41 136.723 183.186C136.735 182.962 136.699 182.738 136.617 182.529C135.83 180.863 135.599 178.989 135.958 177.182C136.296 175.785 137.02 174.511 138.048 173.505C138.148 173.418 138.221 173.304 138.258 173.178C138.296 173.051 138.296 172.916 138.26 172.789C138.224 172.662 138.152 172.548 138.053 172.46C137.954 172.373 137.833 172.314 137.702 172.293L132.386 171.404C132.229 171.378 132.069 171.383 131.914 171.419C131.759 171.455 131.613 171.521 131.484 171.613C131.354 171.706 131.245 171.823 131.161 171.958C131.077 172.093 131.021 172.243 130.995 172.399L127.65 192.853Z' fill='#E4E9F2'/>
      <path d='M173.611 158.701C177.623 158.868 181.708 158.985 185.794 159.086L191.934 159.221L198.067 159.332L198.678 159.342C201.212 159.39 203.632 160.395 205.453 162.154C207.273 163.914 208.358 166.297 208.488 168.823C208.618 171.35 207.784 173.831 206.154 175.768C204.524 177.705 202.22 178.953 199.705 179.26L139.096 186.608L136.628 168.723L196.967 159.422L198.603 179.35L192.376 179.751L186.153 180.127C181.998 180.367 177.84 180.591 173.609 180.764L173.611 158.701Z' fill='#102894'/>
      <path d='M188.9 179.996C180.534 178.78 179.701 178.557 179.426 178.484L181.883 169.352C181.856 169.344 181.82 169.335 181.777 169.325C182.462 169.475 186.715 170.123 190.263 170.638L188.9 179.996Z' fill='#E69E87'/>
      <path d='M199.353 188.124C199.441 188.363 199.478 188.617 199.463 188.871C199.448 189.125 199.381 189.373 199.266 189.599C199.151 189.826 198.99 190.027 198.793 190.189C198.597 190.351 198.369 190.47 198.124 190.54C197.785 190.637 197.425 190.635 197.087 190.535C196.748 190.435 196.446 190.24 196.215 189.974L188.904 180.949C188.764 180.774 188.663 180.571 188.608 180.353C188.554 180.135 188.547 179.909 188.588 179.688C189.044 177.904 188.915 176.02 188.219 174.315C187.621 173.007 186.668 171.893 185.468 171.1C185.353 171.034 185.26 170.936 185.199 170.819C185.139 170.702 185.112 170.57 185.124 170.438C185.135 170.306 185.184 170.181 185.264 170.076C185.345 169.971 185.453 169.891 185.577 169.845L190.628 167.965C190.777 167.909 190.935 167.884 191.094 167.889C191.253 167.895 191.409 167.933 191.554 167.999C191.698 168.065 191.828 168.159 191.936 168.276C192.044 168.392 192.128 168.529 192.183 168.678L199.353 188.124Z' fill='#E4E9F2'/>
      <path d='M160.154 179.145C157.614 178.929 155.076 178.774 152.537 178.627C149.999 178.492 147.461 178.357 144.923 178.267L141.117 178.121L137.312 178.011C134.775 177.927 132.238 177.892 129.702 177.844L130.581 159.648C135.329 160.311 140.084 160.92 144.836 161.544C149.589 162.168 154.348 162.743 159.106 163.327L166.246 164.168L173.391 164.967C178.157 165.479 182.923 165.987 187.7 166.405L185.604 184.34C180.859 183.65 176.103 183.048 171.348 182.449L164.21 181.585L157.068 180.761C152.303 180.236 147.54 179.702 142.772 179.215C138.004 178.729 133.236 178.23 128.463 177.783C126.173 177.569 124.049 176.499 122.515 174.788C120.981 173.077 120.151 170.851 120.191 168.555C120.231 166.259 121.138 164.063 122.73 162.406C124.322 160.749 126.482 159.753 128.778 159.619L129.343 159.587C131.875 159.439 134.408 159.304 136.94 159.12L140.738 158.86L144.536 158.564C147.067 158.373 149.598 158.137 152.129 157.903C154.66 157.657 157.19 157.401 159.72 157.085L160.154 179.145Z' fill='#102894'/>
      <path d='M182.827 155.939L184.162 178.321L145.609 177.869L148.519 158.054L182.827 155.939Z' fill='#102894'/>
      <path d='M138.288 151.311C137.489 151.311 136.703 151.109 136.003 150.724C135.303 150.339 134.713 149.783 134.286 149.109L120.616 127.504L128.623 122.453L137.633 136.692L147.158 116.005L155.762 119.957L142.594 148.56C142.238 149.333 141.679 149.996 140.977 150.479C140.275 150.962 139.456 151.246 138.605 151.303C138.497 151.308 138.392 151.311 138.288 151.311Z' fill='#FFAA00'/>
      <path d='M145.806 159.561C147.696 163.897 184.823 165.699 185.319 158.071C185.635 153.217 181.351 148.979 181.351 139.519C181.351 130.058 190.068 122.03 182.965 114.938C180.904 112.88 177.346 111.551 173.008 111.547C172.683 114.073 157.324 114.082 157.206 111.605C156.62 111.614 156.165 111.623 155.881 111.637C140.918 112.281 147.493 125.231 147.637 137.722C147.813 152.338 143.857 155.094 145.806 159.561Z' fill='#FFAA00'/>
      <path d='M157.182 111.605C157.3 114.082 158.648 117.725 162.81 118C168.357 118.364 172.659 114.073 172.984 111.547H172.971C167.509 111.547 160.366 111.556 157.182 111.605Z' fill='#E69E87'/>
      <path d='M192.292 153.151C191.256 153.151 190.245 152.824 189.406 152.216L162.713 132.888L168.485 124.939L181.063 134.047L176.114 122.151L185.195 118.384L196.833 146.358C197.143 147.104 197.265 147.915 197.186 148.719C197.108 149.523 196.832 150.295 196.382 150.967C195.933 151.639 195.325 152.19 194.611 152.571C193.898 152.952 193.101 153.151 192.292 153.151H192.292Z' fill='#FFAA00'/>
      <path d='M273.071 187.741C265.741 187.749 258.436 186.884 251.31 185.166C241.259 182.779 231.785 178.413 223.445 172.324C213.295 164.839 205.091 154.72 199.061 142.244C193.956 131.684 186.139 124.799 177.612 123.356C170.87 122.216 164.173 124.567 158.754 129.975L158.116 129.339C163.747 123.716 170.723 121.274 177.762 122.466C186.581 123.959 194.64 131.022 199.873 141.851C213.373 169.779 235.394 180.339 251.49 184.281C268.961 188.558 283.428 186.188 283.571 186.162L283.723 187.05C280.194 187.545 276.634 187.776 273.071 187.741Z' fill='black'/>
      <path d='M169.185 129.287C169.185 129.287 168.763 125.199 166.711 123.203C164.659 121.207 164.329 122.942 165.033 124.883C165.738 126.824 167.491 130.962 167.491 130.962' fill='#E69E87'/>
      <path d='M168.639 127.265C168.639 127.265 163.958 118.146 158.8 126.202C157.807 127.752 164.697 132.922 164.697 132.922L168.639 127.265Z' fill='#E69E87'/>
      <path d='M168.486 124.941L162.713 132.89L189.407 152.218L195.179 144.269L168.486 124.941Z' fill='#FFAA00'/>
      <path d='M278.459 159.706L276.719 185.612C276.698 185.918 276.741 186.225 276.843 186.513C276.946 186.802 277.107 187.066 277.317 187.29C277.526 187.514 277.779 187.692 278.061 187.814C278.342 187.936 278.646 187.999 278.952 187.999H315.191C315.745 187.999 316.279 187.794 316.69 187.424C317.101 187.053 317.361 186.544 317.417 185.994L320.099 160.087C320.131 159.775 320.097 159.461 320 159.163C319.903 158.866 319.744 158.592 319.535 158.36C319.325 158.128 319.068 157.942 318.782 157.815C318.496 157.688 318.186 157.622 317.872 157.622H280.693C280.126 157.622 279.579 157.837 279.165 158.224C278.75 158.611 278.498 159.14 278.459 159.706Z' fill='#A6C0FF'/>
      <path d='M279.908 159.942L278.121 186.051C278.114 186.167 278.13 186.282 278.169 186.391C278.207 186.5 278.268 186.6 278.347 186.684C278.427 186.769 278.522 186.836 278.628 186.882C278.735 186.928 278.849 186.952 278.965 186.952H315.156C315.365 186.952 315.567 186.875 315.722 186.735C315.877 186.595 315.975 186.402 315.997 186.195L318.75 160.085C318.762 159.967 318.75 159.849 318.713 159.736C318.676 159.624 318.616 159.521 318.537 159.433C318.458 159.345 318.361 159.275 318.253 159.227C318.144 159.179 318.027 159.154 317.909 159.154H280.75C280.536 159.155 280.33 159.236 280.174 159.383C280.017 159.529 279.922 159.729 279.908 159.942Z' fill='white'/>
      <path d='M316.596 184.877H269.164C268.301 184.877 267.601 185.575 267.601 186.437C267.601 187.299 268.301 187.997 269.164 187.997H316.596C317.459 187.997 318.159 187.299 318.159 186.437C318.159 185.575 317.459 184.877 316.596 184.877Z' fill='#A6C0FF'/>
      <path d='M236.415 127.339C239.844 127.339 242.624 124.563 242.624 121.14C242.624 117.716 239.844 114.94 236.415 114.94C232.987 114.94 230.207 117.716 230.207 121.14C230.207 124.563 232.987 127.339 236.415 127.339Z' fill='#D9E4FF'/>
      <path d='M247.945 114.508C247.615 114.508 247.291 114.426 247 114.27C246.71 114.114 246.464 113.887 246.283 113.612C245.909 113.043 245.485 112.508 245.015 112.015L244.786 111.79C243.687 110.69 242.382 109.817 240.944 109.222C239.507 108.626 237.967 108.32 236.411 108.32C234.855 108.32 233.314 108.626 231.877 109.222C230.44 109.817 229.135 110.69 228.036 111.79C227.478 112.344 226.98 112.955 226.548 113.612C226.251 114.032 225.803 114.32 225.298 114.417C224.793 114.514 224.27 114.411 223.839 114.129C223.408 113.848 223.104 113.411 222.99 112.91C222.876 112.409 222.961 111.884 223.228 111.444C223.81 110.559 224.481 109.737 225.231 108.989C228.091 106.128 231.942 104.473 235.989 104.365C240.035 104.257 243.969 105.705 246.978 108.409H247.009L247.599 108.989C248.349 109.736 249.02 110.559 249.602 111.443C249.797 111.742 249.908 112.088 249.923 112.444C249.938 112.801 249.856 113.155 249.687 113.468C249.517 113.782 249.265 114.045 248.959 114.228C248.652 114.411 248.302 114.508 247.944 114.508L247.945 114.508Z' fill='#D9E4FF'/>
      <path d='M216.587 109.023C216.229 109.022 215.877 108.925 215.57 108.741C215.262 108.557 215.01 108.293 214.841 107.977C214.672 107.662 214.591 107.306 214.608 106.949C214.625 106.591 214.738 106.245 214.937 105.947C215.89 104.513 216.983 103.177 218.201 101.959C223.033 97.1374 229.584 94.4287 236.416 94.4287C243.247 94.4287 249.799 97.1374 254.63 101.959C255.848 103.174 256.941 104.506 257.894 105.937C258.049 106.153 258.158 106.398 258.215 106.658C258.273 106.917 258.278 107.185 258.229 107.446C258.181 107.707 258.081 107.956 257.934 108.178C257.787 108.4 257.598 108.59 257.376 108.736C257.155 108.883 256.906 108.984 256.644 109.033C256.383 109.082 256.114 109.078 255.854 109.022C255.594 108.965 255.349 108.857 255.132 108.703C254.914 108.55 254.73 108.354 254.591 108.128C253.783 106.917 252.857 105.788 251.826 104.76C247.738 100.68 242.195 98.389 236.415 98.389C230.636 98.389 225.093 100.68 221.005 104.76C219.974 105.791 219.048 106.923 218.241 108.137C218.06 108.409 217.814 108.633 217.526 108.787C217.237 108.942 216.915 109.023 216.587 109.023Z' fill='#D9E4FF'/>
      <path d='M264.366 103.637C264.038 103.637 263.716 103.556 263.428 103.402C263.139 103.248 262.894 103.025 262.712 102.753C261.545 101 260.207 99.3668 258.717 97.8776C252.802 91.9728 244.78 88.6557 236.415 88.6557C228.051 88.6557 220.029 91.9728 214.114 97.8776C212.622 99.3708 211.28 101.005 210.105 102.758C209.807 103.182 209.355 103.472 208.845 103.567C208.335 103.661 207.808 103.553 207.377 103.264C206.946 102.976 206.646 102.53 206.54 102.023C206.434 101.517 206.531 100.988 206.81 100.552C208.129 98.5852 209.635 96.7512 211.31 95.0759C217.969 88.4286 226.999 84.6943 236.415 84.6943C245.831 84.6943 254.861 88.4286 261.52 95.0759C263.196 96.7509 264.702 98.5876 266.015 100.559C266.214 100.857 266.327 101.204 266.344 101.561C266.361 101.919 266.281 102.274 266.112 102.59C265.942 102.906 265.691 103.17 265.383 103.354C265.076 103.538 264.724 103.636 264.366 103.636V103.637Z' fill='#D9E4FF'/>
      <path d='M258.249 137.946C266.539 137.946 273.26 131.235 273.26 122.957C273.26 114.679 266.539 107.968 258.249 107.968C249.958 107.968 243.237 114.679 243.237 122.957C243.237 131.235 249.958 137.946 258.249 137.946Z' fill='#FF708D'/>
      <path d='M264.17 130.694C263.956 130.694 263.743 130.651 263.546 130.567C263.349 130.483 263.171 130.361 263.022 130.206L251.179 117.913C251.034 117.763 250.92 117.585 250.843 117.391C250.766 117.196 250.729 116.988 250.733 116.779C250.737 116.57 250.782 116.364 250.866 116.172C250.949 115.981 251.07 115.807 251.221 115.662C251.372 115.517 251.55 115.404 251.745 115.327C251.94 115.251 252.148 115.214 252.357 115.218C252.567 115.222 252.773 115.267 252.965 115.351C253.157 115.435 253.33 115.556 253.475 115.707L265.318 128C265.535 128.225 265.681 128.508 265.737 128.815C265.794 129.122 265.759 129.438 265.636 129.725C265.514 130.012 265.31 130.257 265.049 130.429C264.788 130.601 264.483 130.693 264.17 130.693V130.694Z' fill='white'/>
      <path d='M252.327 130.694C252.015 130.694 251.709 130.602 251.448 130.43C251.187 130.258 250.983 130.013 250.861 129.726C250.738 129.439 250.703 129.122 250.76 128.815C250.817 128.509 250.962 128.225 251.179 128L263.022 115.708C263.167 115.557 263.34 115.436 263.532 115.353C263.724 115.269 263.93 115.224 264.14 115.22C264.349 115.216 264.557 115.253 264.752 115.329C264.946 115.406 265.124 115.519 265.275 115.664C265.426 115.809 265.547 115.982 265.63 116.174C265.714 116.365 265.759 116.571 265.763 116.78C265.767 116.989 265.73 117.197 265.654 117.391C265.577 117.586 265.463 117.763 265.318 117.914L253.475 130.207C253.327 130.361 253.148 130.484 252.951 130.568C252.754 130.651 252.541 130.694 252.327 130.694Z' fill='white'/>
      <path d='M294.453 165.83L293.628 165.006L288.276 170.35L289.101 171.174L294.453 165.83Z' fill='#D9E4FF'/>
      <path d='M293.628 171.173L294.453 170.35L289.101 165.006L288.276 165.829L293.628 171.173Z' fill='#D9E4FF'/>
      <path d='M306.942 165.83L306.117 165.006L300.765 170.35L301.589 171.174L306.942 165.83Z' fill='#D9E4FF'/>
      <path d='M306.116 171.173L306.941 170.35L301.589 165.006L300.764 165.829L306.116 171.173Z' fill='#D9E4FF'/>
      <path d='M292.196 180.306L291.333 179.516C291.427 179.413 293.694 176.994 297.95 176.994C302.231 176.994 304.228 179.441 304.311 179.546L303.394 180.273C303.326 180.189 301.639 178.163 297.95 178.163C294.204 178.164 292.215 180.284 292.196 180.306Z' fill='#D9E4FF'/>
      <path d='M77.1593 187.995H23.1396C19.177 184.837 18.9037 177.723 23.0111 174.546C26.1768 172.097 30.9629 172.752 33.9383 175.299C33.3823 173.403 33.3714 171.389 33.9068 169.487C34.8745 166.062 37.0251 162.582 40.3104 161.197C48.6906 157.666 54.6451 165.064 55.8383 169.2C57.9836 166.048 62.7831 164.384 65.796 166.505C69.6125 169.191 69.4398 173.426 66.3953 176.466C70.454 175.711 73.5065 176.293 76.3201 179.309C78.4603 181.605 78.7882 185.518 77.1593 187.995Z' fill='#8CFAC7'/>
      <path d='M272.05 36.9286H170.032C170.609 33.0588 172.729 29.4718 175.969 27.7206C179.975 25.5543 184.844 26.1937 189.362 26.7525C193.88 27.3113 198.874 27.6247 202.583 24.9883C204.45 23.6617 205.771 21.7278 207.282 20.0072C211.603 15.0847 218.415 11.7174 224.735 13.4551C227.915 14.3295 230.63 16.3743 233.645 17.7103C236.813 19.107 240.277 19.7067 243.731 19.457C247.294 19.1935 250.838 18.03 254.371 18.5519C258.85 19.2174 262.519 22.5 265.174 26.1617C267.69 29.6326 269.488 32.998 272.05 36.9286Z' fill='#D9E4FF'/>
      <path d='M144.47 66.5176H42.5626C42.054 65.5616 41.7716 64.5019 41.737 63.4199C41.7024 62.3379 41.9166 61.2624 42.3631 60.2759C42.8096 59.2895 43.4765 58.4183 44.3127 57.7292C45.1489 57.0402 46.132 56.5516 47.1868 56.301C50.5139 55.513 54.2745 57.13 57.3072 55.5553C59.6603 54.3324 60.7476 51.5721 61.2482 48.973C61.7487 46.374 61.8908 43.6286 63.1548 41.3015C65.2789 37.3939 70.3224 35.6485 74.6769 36.5797C79.0314 37.5109 82.6485 40.6774 85.0296 44.4355C86.0091 45.9804 86.8988 47.7257 88.5128 48.5885C90.3766 49.5827 92.6959 49.109 94.6098 48.2183C96.5237 47.3277 98.2626 46.0471 100.266 45.3856C102.31 44.7578 104.499 44.7878 106.525 45.4713C108.551 46.1548 110.31 47.4572 111.553 49.1941C113.274 51.7058 113.994 55.1631 116.694 56.5707C117.841 57.089 119.092 57.3388 120.351 57.3011C125.351 57.4218 130.491 56.2582 135.277 57.7118C137.381 58.3826 139.302 59.5247 140.896 61.051C142.489 62.5772 143.711 64.4471 144.47 66.5176Z' fill='#D9E4FF'/>
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='343' height='195' fill='white'/>
      </clipPath>
    </defs>
  </svg>;
