import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fillRule='evenodd' clipRule='evenodd'
        d='M6.30376 6.64002C6.053 4.43335 7.7791 2.5 9.99997 2.5C12.2208 2.5 13.9469 4.43335 13.6962 6.64002L13.4778 8.56165C13.2768 10.3305 11.7802 11.6667 9.99997 11.6667C8.21977 11.6667 6.72313 10.3305 6.52213 8.56165L6.30376 6.64002ZM12.6198 6.5177L12.4014 8.43933C12.2626 9.6607 11.2292 10.5833 9.99997 10.5833C8.77075 10.5833 7.73733 9.6607 7.59854 8.43933L7.38017 6.5177C7.20244 4.95366 8.42586 3.58333 9.99997 3.58333C11.5741 3.58333 12.7975 4.95366 12.6198 6.5177Z'
        fill='currentColor'/>
      <path fillRule='evenodd' clipRule='evenodd'
        d='M3.57434 16.4153C4.08272 14.1277 6.11178 12.5 8.45528 12.5H11.5447C13.8882 12.5 15.9172 14.1277 16.4256 16.4153L16.5089 16.7901C16.5898 17.1544 16.3126 17.5 15.9394 17.5H4.0605C3.68731 17.5 3.4101 17.1544 3.49106 16.7901L3.57434 16.4153ZM11.5447 13.5833C13.2996 13.5833 14.8294 14.7485 15.3086 16.4167H4.69134C5.17058 14.7485 6.70039 13.5833 8.45528 13.5833H11.5447Z'
        fill='currentColor'/>
    </svg>
  );
};
