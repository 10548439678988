import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' { ...props }>
    <path fillRule='evenodd' clipRule='evenodd'
      d='M10.2959 12.2519C11.2623 12.2519 12.0459 11.4684 12.0459 10.5019V4.66856C12.0459 3.79818 11.4104 3.07622 10.5782 2.94122C10.5665 2.82782 10.5439 2.7174 10.5115 2.61109C10.4504 2.41079 10.2385 2.29794 10.0382 2.35903C9.83793 2.42012 9.72508 2.63202 9.78617 2.83232C9.79477 2.86051 9.80216 2.88928 9.80826 2.91856H5.62918C4.66269 2.91856 3.87918 3.70206 3.87918 4.66856V10.3059C3.85011 10.2999 3.82155 10.2925 3.79356 10.284C3.59326 10.2229 3.38136 10.3357 3.32027 10.536C3.25918 10.7363 3.37202 10.9482 3.57232 11.0093C3.702 11.0489 3.83781 11.0737 3.97766 11.0821C4.21701 11.7634 4.86605 12.2519 5.62918 12.2519H10.2959ZM5.62918 11.4936H10.2959C10.8435 11.4936 11.2875 11.0496 11.2875 10.5019V4.66856C11.2875 4.12088 10.8435 3.67689 10.2959 3.67689H5.62918C5.0815 3.67689 4.63752 4.12088 4.63752 4.66856V10.5019C4.63752 11.0496 5.0815 11.4936 5.62918 11.4936Z'
      fill='currentColor'/>
    <path
      d='M3.13487 9.62531C3.19596 9.82561 3.08312 10.0375 2.88282 10.0986C2.68252 10.1597 2.47062 10.0468 2.40953 9.84654C2.36008 9.68442 2.33362 9.51271 2.33362 9.33551V8.78499C2.33362 8.57558 2.50338 8.40583 2.71278 8.40583C2.92219 8.40583 3.09195 8.57558 3.09195 8.78499V9.33551C3.09195 9.43692 3.10705 9.5341 3.13487 9.62531Z'
      fill='currentColor'/>
    <path
      d='M3.09195 6.96828C3.09195 7.17769 2.92219 7.34745 2.71278 7.34745C2.50338 7.34745 2.33362 7.17769 2.33362 6.96828V5.86725C2.33362 5.65784 2.50338 5.48808 2.71278 5.48808C2.92219 5.48808 3.09195 5.65784 3.09195 5.86725V6.96828Z'
      fill='currentColor'/>
    <path
      d='M3.09195 4.05054C3.09195 4.25995 2.92219 4.42971 2.71278 4.42971C2.50338 4.42971 2.33362 4.25995 2.33362 4.05054V3.50002C2.33362 3.28426 2.36583 3.07533 2.42598 2.87814C2.48707 2.67784 2.69897 2.56499 2.89927 2.62609C3.09956 2.68718 3.21241 2.89908 3.15132 3.09938C3.11281 3.22565 3.09195 3.36005 3.09195 3.50002V4.05054Z'
      fill='currentColor'/>
    <path
      d='M4.06214 2.18856C3.86184 2.24965 3.64994 2.1368 3.58885 1.93651C3.52776 1.73621 3.6406 1.52431 3.8409 1.46322C4.03809 1.40307 4.24702 1.37086 4.46278 1.37086H4.83973C5.04913 1.37086 5.21889 1.54062 5.21889 1.75002C5.21889 1.95943 5.04913 2.12919 4.83973 2.12919H4.46278C4.32282 2.12919 4.18841 2.15005 4.06214 2.18856Z'
      fill='currentColor'/>
    <path
      d='M9.34729 1.44686C9.54759 1.50795 9.66044 1.71985 9.59935 1.92015C9.53825 2.12045 9.32635 2.2333 9.12606 2.1722C9.03465 2.14433 8.93725 2.12919 8.83563 2.12919H8.38751C8.1781 2.12919 8.00834 1.95943 8.00834 1.75002C8.00834 1.54062 8.1781 1.37086 8.38751 1.37086H8.83563C9.01305 1.37086 9.18497 1.39735 9.34729 1.44686Z'
      fill='currentColor'/>
    <path
      d='M6.08363 2.12919C5.87422 2.12919 5.70446 1.95943 5.70446 1.75002C5.70446 1.54062 5.87422 1.37086 6.08363 1.37086H6.90869C7.1181 1.37086 7.28786 1.54062 7.28786 1.75002C7.28786 1.95943 7.1181 2.12919 6.90869 2.12919H6.08363Z'
      fill='currentColor'/>
  </svg>
);
