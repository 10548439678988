import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' { ...props }>
      <path d='M13.2662 19.8765V15.8765H16.2662L12.2662 10.8765L8.26624 15.8765H11.2662V19.8765H13.2662Z' fill='currentColor'/>
      <path
        d='M7.26624 19.8765H9.26624V17.8765H7.26624C5.61224 17.8765 4.26624 16.5305 4.26624 14.8765C4.26624 13.4725 5.46524 12.1205 6.93924 11.8615L7.52024 11.7595L7.71224 11.2015C8.41524 9.15046 10.1612 7.87646 12.2662 7.87646C15.0232 7.87646 17.2662 10.1195 17.2662 12.8765V13.8765H18.2662C19.3692 13.8765 20.2662 14.7735 20.2662 15.8765C20.2662 16.9795 19.3692 17.8765 18.2662 17.8765H15.2662V19.8765H18.2662C20.4722 19.8765 22.2662 18.0825 22.2662 15.8765C22.2662 13.9965 20.9612 12.4145 19.2102 11.9885C18.7732 8.54647 15.8262 5.87646 12.2662 5.87646C9.51024 5.87646 7.11624 7.48746 6.02324 10.0265C3.87524 10.6685 2.26624 12.6965 2.26624 14.8765C2.26624 17.6335 4.50924 19.8765 7.26624 19.8765Z'
        fill='currentColor'/>
    </svg>

  );
};
