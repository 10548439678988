import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M6.22566 4.81108C5.83514 4.42056 5.20197 4.42056 4.81145 4.81108C4.42092 5.20161 4.42092 5.83477 4.81145 6.2253L10.5862 12L4.81151 17.7747C4.42098 18.1652 4.42098 18.7984 4.81151 19.1889C5.20203 19.5794 5.8352 19.5794 6.22572 19.1889L12.0004 13.4142L17.7751 19.1889C18.1656 19.5794 18.7988 19.5794 19.1893 19.1889C19.5798 18.7984 19.5798 18.1652 19.1893 17.7747L13.4146 12L19.1893 6.2253C19.5799 5.83477 19.5799 5.20161 19.1893 4.81108C18.7988 4.42056 18.1657 4.42056 17.7751 4.81108L12.0004 10.5858L6.22566 4.81108Z'
      fill='currentColor'/>
  </svg>


);
