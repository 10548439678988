import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' { ...props }>
    <path fillRule='evenodd' clipRule='evenodd'
      d='M3.99369 5.74866C3.99369 5.38968 4.28471 5.09866 4.64369 5.09866L19.3562 5.09866C19.7152 5.09866 20.0062 5.38968 20.0062 5.74866C20.0062 6.10765 19.7152 6.39866 19.3562 6.39866L4.64369 6.39866C4.28471 6.39866 3.99369 6.10765 3.99369 5.74866Z'
      fill='currentColor'/>
    <path fillRule='evenodd' clipRule='evenodd'
      d='M3.99369 18.3649C3.99369 18.0059 4.28471 17.7149 4.64369 17.7149L19.3562 17.7149C19.7152 17.7149 20.0062 18.0059 20.0062 18.3649C20.0062 18.7239 19.7152 19.0149 19.3562 19.0149L4.64369 19.0149C4.28471 19.0149 3.99369 18.7239 3.99369 18.3649Z'
      fill='currentColor'/>
    <path fillRule='evenodd' clipRule='evenodd'
      d='M3.99373 12C3.99373 11.641 4.28475 11.35 4.64373 11.35L19.3562 11.35C19.7152 11.35 20.0063 11.641 20.0063 12C20.0063 12.359 19.7152 12.65 19.3563 12.65L4.64373 12.65C4.28475 12.65 3.99373 12.359 3.99373 12Z'
      fill='currentColor'/>
  </svg>

);
