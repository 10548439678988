import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
  <svg width='220' height='241' viewBox='0 0 220 241' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9.06196 176.023C7.47267 175.103 6.15328 173.78 5.23603 172.189C4.31878 170.598 3.83589 168.793 3.83573 166.957C3.83557 165.12 4.31817 163.315 5.23514 161.724C6.15212 160.132 7.47124 158.81 9.06038 157.889L87.8254 112.269C89.0166 111.562 90.3363 111.098 91.708 110.905C93.0798 110.711 94.4763 110.791 95.8168 111.141C97.1573 111.49 98.4151 112.102 99.5176 112.941C100.62 113.78 101.545 114.829 102.239 116.028C102.934 117.227 103.383 118.551 103.562 119.925C103.741 121.299 103.646 122.694 103.282 124.031C102.918 125.368 102.292 126.619 101.442 127.712C100.591 128.806 99.5317 129.719 98.3255 130.401L19.5593 176.019C17.9649 176.944 16.1544 177.432 14.3111 177.433C12.4678 177.433 10.6571 176.947 9.06196 176.023Z'
      fill='currentColor'/>
    <path
      d='M109.582 142.452C108.133 141.615 106.708 140.71 105.308 139.735C92.484 130.797 83.7367 117.13 80.9898 101.741C78.2429 86.3531 81.7214 70.5038 90.6603 57.6803C99.5991 44.8568 113.266 36.1094 128.654 33.3625C144.043 30.6156 159.892 34.0943 172.715 43.0331C185.178 51.7221 193.805 64.8882 196.795 79.7842C199.784 94.6801 196.907 110.155 188.761 122.98C180.616 135.806 167.833 144.99 153.08 148.618C138.326 152.245 122.742 150.036 109.578 142.45L109.582 142.452ZM157.985 58.4732C149.381 53.49 139.168 52.0751 129.534 54.5318C119.9 56.9885 111.611 63.1213 106.444 71.6159C101.278 80.1105 99.644 90.2911 101.893 99.9758C104.143 109.661 110.096 118.079 118.478 123.427C126.86 128.775 137.003 130.626 146.734 128.585C156.464 126.544 165.009 120.773 170.535 112.508C176.062 104.243 178.131 94.1417 176.299 84.3694C174.467 74.597 168.881 65.931 160.736 60.2284C159.839 59.5968 158.919 59.0124 157.985 58.4732Z'
      fill='currentColor'/>
  </svg>
);
