import React, { SVGProps } from 'react';


export default (props: SVGProps<SVGSVGElement>) => (
  <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M43.8944 18.358C43.6364 17.602 42.9544 17.068 42.1584 17.006L30.7564 16.1L25.8224 5.178C25.5004 4.46 24.7864 4 24.0004 4C23.2144 4 22.5004 4.46 22.1784 5.176L17.2444 16.1L5.84245 17.006C5.06045 17.068 4.38645 17.582 4.12045 18.32C3.85445 19.058 4.04245 19.884 4.60445 20.432L13.0304 28.646L10.0504 41.55C9.86645 42.348 10.1884 43.178 10.8624 43.644C11.2064 43.88 11.6024 44 12.0004 44C12.3864 44 12.7744 43.888 13.1104 43.664L24.0004 36.404L34.8904 43.664C35.5864 44.128 36.5004 44.11 37.1804 43.616C37.8564 43.122 38.1544 42.256 37.9244 41.452L34.2664 28.652L43.3384 20.488C43.9324 19.952 44.1504 19.116 43.8944 18.358Z'
      fill='currentColor'/>
  </svg>

);
